<template>
  <v-container>
    <v-row justify="center">
      <v-col md="8">
        <v-card>
          <v-card-title>
            Book Out
          </v-card-title>
          <v-card-text>
            <departure-form
              v-if="!hasSubmitted"
              :on-success="onSuccess"
              :pilot-briefing-url="pilotBriefingUrl"
              :show-terms="false"
              submit-button-text="Book Out"
              :pre-fill-data="instanceData"
            />
            <div v-if="hasSubmitted">
              <p class="text-body">
                Thank you for booking out, we've sent an email confirmation, and
                the airfield has been informed too.
              </p>
              <p class="text-body">
                You can close this tab, but to book out again or for another
                aircraft just click the reset button to start again.
              </p>
              <v-btn
                color="primary"
                @click="resetForm"
              >
                Reset
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {supabase} from "../../../supabase";
import DepartureForm from "../../../components/DepartureForm";
import base64url from "base64url";
import {DateTime} from "luxon";

export default {
  name: "Form",
  props: {
    payload: {type: String, required: true}
  },

  created: async function () {
    try {
      const jsonPayload = base64url.decode(this.payload);
      const jsonData = JSON.parse(jsonPayload);
      this.pilotBriefingUrl = jsonData.pilot_briefing_url;

      if (jsonData.aircraft_reg) {
        jsonData.aircraft_data = {registration: jsonData.aircraft_reg};
      }

      this.instanceData = jsonData;
    } catch (_) {
      // do nothing
    }
  },

  components: {
    DepartureForm
  },

  data: () => ({
    instanceData: null,
    pilotBriefingUrl: null,
    hasSubmitted: false,
  }),

  methods: {
    async onSuccess(formData) {
      formData.date_time = DateTime.fromISO(`${formData.date}T${formData.time}:00`).toUTC().toISO();
      const reqData = {
        ...formData,
        airfield_id: this.instanceData.airfield_id || formData.airfield_id
      };
      delete reqData["pilot_briefing_url"];

      const {error} = await supabase.functions.invoke("departures-widgetCreate", {
        body: reqData,
      });

      if (error) {
        return console.error(error)
      }

      this.hasSubmitted = true;
    },

    resetForm: function () {
      this.hasSubmitted = false;
    }
  }
};
</script>
